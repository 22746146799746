/* eslint-disable dot-notation */
import axios from 'axios'
import Swal from 'sweetalert2'
import store from '~/store'
import i18n from '~/plugins/i18n'
import Cookies from 'js-cookie'

// Request interceptor
axios.interceptors.request.use((request) => {
  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})
axios.interceptors.request.use((config) => {
  if (Cookies.get('token') !== null) {
    config.headers['Authorization'] = `Bearer ${Cookies.get('token')}`
  }
  return config
})
// Response interceptor
/*
axios.interceptors.response.use(response => response, (error) => {
  const { status } = error.response

  if (status >= 500) {
    Swal.fire({
      type: 'error',
      title: i18n.t('error_alert_title'),
      text: i18n.t('error_alert_text'),
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  }
  return Promise.reject(error)
})
*/
// Response interceptor
axios.interceptors.response.use(response => response, (error) => {
  const { status } = error.response
  if (status === 422) {
    // console.log(error)
    const errors = error.response.data.error.errors
    let values = ''
    for (const field of Object.keys(errors)) {
      values += errors[field][0] + ' <br/> '
    }
    Swal.fire({
      icon: 'error',
      title: i18n.t('error_validation_title'),
      html: values,
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  } else if (status === 401) {
    Swal.fire({
      icon: 'error',
      title: i18n.t('error_unauthorized_title'),
      text: error.response.data.errors,
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  } else if (status === 403) {
    Swal.fire({
      icon: 'error',
      title: i18n.t('error_persimmision_title'),
      text: i18n.t('error_persimmision_text'),
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  } else if (status === 405) {
    Swal.fire({
      icon: 'error',
      title: i18n.t('error_not_alowed_title'),
      text: i18n.t('error_not_alowed_text'),
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  } else if (status >= 500) {
    Swal.fire({
      icon: 'error',
      title: i18n.t('error_alert_title'),
      text: error.response.data.error.message,
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  } else {
    Swal.fire({
      icon: 'error',
      title: i18n.t('error_alert_title'),
      text: '',
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  }
  // console.log(error)
  return Promise.reject(error)
})
