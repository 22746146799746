import Vue from 'vue'
import Child from './Child'
import Button from './Button'
import { HasError, AlertError, AlertSuccess } from 'vform/src/components/bootstrap4'

// Components that are registered globaly.
[
  Child,
  Button,
  HasError,
  AlertError,
  AlertSuccess
].forEach((Component) => {
  Vue.component(Component.name, Component)
})
