import * as types from '../mutation-types'
export const state = {
  registerRef: null,
  registerType: null,
  rdefaultKey: null
}
export const getters = {
  registerRef: state => state.registerRef,
  registerType: state => state.registerType,
  defaultKey: state => state.defaultKey
}
export const mutations = {
  [types.SET_REGISTER_REF] (state, val) {
    state.registerRef = val
  },
  [types.SET_REGISTER_TYPE] (state, val) {
    state.registerType = val
  },
  [types.SET_REGISTER_DEFAULT_KEY] (state, val) {
    state.defaultKey = val
  }
}
export const actions = {
  setRegisterRef({ commit }, val) {
    commit(types.SET_REGISTER_REF, val.ref)
    commit(types.SET_REGISTER_TYPE, val.type)
  },
  setDefaultKey({ commit }, val) {
    commit(types.SET_REGISTER_DEFAULT_KEY, val)
  }
}