<template>
  <div class="starter-layout">
    <section class="login-block">
      <div class="container mt-2">
        <child />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MessagesLayout'
}
</script>
 
<style>
.login-block{
  background: #e9ecef;
  display: flex;
  min-height: 100vh;
 
  width:100%;
  padding : 50px 0;
}
.starter-layout .container{background:#fff; border-radius: 10px; box-shadow:15px 20px 0px rgba(0,0,0,0.1);align-self: center; max-width: 450px;}
.carousel-inner{border-radius:0 10px 10px 0;}
.carousel-caption{text-align:left; left:5%;}
.login-sec{padding: 50px 30px; position:relative;}
.login-sec .copy-text{position:absolute; width:80%; bottom:20px; font-size:13px; text-align:center;}
.login-sec .copy-text i{color:#FEB58A;}
.login-sec .copy-text a{color:#E36262;}
</style>