import axios from 'axios'
import * as types from '../mutation-types'

const apiUrl = process.env.VUE_APP_ENDPOINT_V1

// state
export const state = {
    filters: {
        cash_register_types: null,
        operations_types: null,
        companies: null
    }
}

// getters
export const getters = {
    filters: state => state.filters
}

// mutations
export const mutations = {
    [types.SET_OPERATIONS_FILTERS] (state, { filters }) {
        state.filters = filters
    },
    [types.FETCH_USER_FAILURE] (state) {
        state.filters = null
    }
}

// actions
export const actions = {
    async fetchOperationFilters ({ commit }) {
        try {
            const { data } = await axios.get(`${apiUrl}operations/filters`)
            commit(types.SET_OPERATIONS_FILTERS, { filters: data.data })
        } catch (e) {
            commit(types.SET_OPERATIONS_FILTERS_FAILURE)
        }
    }
}
