<template>
  <div id="app" data-app>
    <loading ref="loading" />
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import Loading from "./Loading";
// Load layout components dynamically.
const requireContext = require.context("~/layouts", false, /.*\.vue$/);
const layouts = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});
export default {
  el: "#app",
  components: {
    Loading,
  },
  data: () => ({
    layout: null,
    defaultLayout: "default",
  }),
  metaInfo: {
    title: "Print CRM",
    titleTemplate: "%s | Print CRM",
  },
  mounted() {
    this.$loading = this.$refs.loading;
    // Light theme
    this.$vuetify.theme.themes.light.primary = "#4caf50";

    // Dark theme
    this.$vuetify.theme.themes.dark.primary = "#4caf50";
  },
  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout;
      }
      this.layout = layouts[layout];
    },
  },
};
</script>
<style>
[class*="col"] {
  padding: 0 15px !important;
}
.row {
  margin: 0 -15px !important;
}
</style>