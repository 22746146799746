import Vue from 'vue'
import Accounting from 'accounting'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'
import App from '~/components/App'
import VueMask from 'v-mask'

import '~/plugins'
import '~/components'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import vuetify from '~/plugins/vuetify'

Vue.config.productionTip = false

Vue.use(VueMaterial)

Vue.material.locale.dateFormat = 'dd.MM.yyyy'
Vue.material.locale.firstDayOfAWeek = 1
Vue.material.locale.days = [
  'Неділя',
  'Понеділок',
  'Вівторок',
  'Середа',
  'Четвер',
  'Пятниця',
  'Субота'
]
Vue.material.locale.shortDays = [
  'Нед',
  'Пон',
  'Вів',
  'Сер',
  'Чет',
  'Пят',
  'Суб'
]
Vue.material.locale.shorterDays = ['Н', 'П', 'В', 'С', 'Ч', 'П', 'С']
Vue.material.locale.months = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень'
]
Vue.material.locale.shortMonths = [
  'Січ',
  'Лют',
  'Бер',
  'Кві',
  'Тра',
  'Чер',
  'Лип',
  'Серп',
  'Вер',
  'Жов',
  'Лист',
  'Груд'
]
Vue.material.locale.shorterMonths = [
  'C',
  'F',
  'M',
  'A',
  'M',
  'Ju',
  'Ju',
  'A',
  'Se',
  'O',
  'N',
  'D'
]
Vue.material.locale.cancel = 'Скасувати'
Vue.material.locale.confirm = 'Ок'

Vue.filter('currency', value => {
  return Accounting.formatMoney(value, ' грн', 2, ',', '.', '%v%s')
})
Vue.filter('currencyUsd', value => {
  return Accounting.formatMoney(value, '$', 2, ',', '.', '%v%s')
})
Vue.filter('image', value => {
  return process.env.VUE_APP_ENDPOINT_URL + value
})
Vue.use(VueMask)
/* eslint-disable no-new */
new Vue({
  vuetify,
  i18n,
  store,
  router,
  ...App
})

Vue.directive('mask', {
  inserted: function (el, binding) {
    const mask = binding.value
        const first = mask.indexOf('_')
        const clean = mask.replace(/[^0-9_]/gm, '')
        const indexes = []

    for (let i = 0; i < clean.length; i++) {
      if (!isNaN(clean[i])) {
        indexes.push(i)
      }
    }

    el.value = mask
    el.clean = mask.replace(/[^0-9]/gm, '')

    function maskIt (event, start) {
      let value = el.value
          let filtred = value.replace(/[^0-9]/gm, '')
          let result = ''

      if (value.length < first) {
        value = mask + value
        filtred = value.replace(/[^0-9]/gm, '')
      }

      for (var i = 0; i < filtred.length; i++) {
        if (indexes.indexOf(i) == -1) {
          result += filtred[i]
        }
      }

      value = ''
      let cursor = 0

      for (var i = 0; i < mask.length; i++) {
        if (mask[i] == '_' && result) {
          value += result[0]
          result = result.slice(1)
          cursor = i + 1
        } else {
          value += mask[i]
        }
      }

      if (cursor < first) {
        cursor = first
      }

      el.value = value

      el.clean = el.value.replace(/[^0-9]/gm, '')

      el.setSelectionRange(cursor, cursor)
    }

    el.addEventListener('focus', function (event) {
      event.preventDefault()
    })

    el.addEventListener('click', function (event) {
      event.preventDefault()
      let start = el.value.indexOf('_')

      if (start == -1) {
        start = el.value.length
      }

      el.setSelectionRange(start, start)
    })

    el.addEventListener('paste', function (event) {
      const start = el.selectionStart

      if (start < first) {
        el.value = '_' + el.value
      }
    })

    el.addEventListener('input', function (event) {
      const start = el.selectionStart
      maskIt(event, start)
    })
  }
})
