function page(path) {
  return () =>
    import(/* webpackChunkName: 'pages' */ `~/pages/${path}`).then(
      m => m.default || m
    )
}

function getRole() {
  return localStorage.getItem('role') || null
}
function openOnlyAdmin(next) {
  if (getRole() === 'admin') {
    next()
  } else {
    next({ name: 'dashboard' })
  }
}

export default [
  // Welcome
  // { path: '/', component: page('welcome.vue') },
  // Auth
  { path: '/', name: 'login', component: page('auth/login.vue') },
  { path: '/register', name: 'register', component: page('auth/register.vue') },
  {
    path: '/password/reset',
    name: 'password.request',
    component: page('auth/password/email.vue')
  },
  {
    path: '/password/reset/:token',
    name: 'password.reset',
    component: page('auth/password/reset.vue')
  },
  // { path: '/email/verify/:id', name: 'verification.verify', component: page('auth/verification/verify.vue') },
  {
    path: '/email/verify',
    name: 'verification.verify',
    component: page('auth/verification/verify.vue')
  },
  // { path: '/email/resend', name: 'verification.resend', component: page('auth/verification/resend.vue') },
  // Dashboard
  { path: '/dashboard', name: 'dashboard', component: page('dashboard.vue') },
  // Profile
  { path: '/profile', name: 'profile', component: page('profile/index.vue') },
  // Users
  {
    path: '/users',
    name: 'users',
    component: page('users/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: page('users/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/users/edit/:id',
    name: 'users.edit',
    component: page('users/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // Roles
  {
    path: '/roles',
    name: 'roles',
    component: page('roles/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'admin') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/roles/create',
    name: 'roles.create',
    component: page('roles/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/roles/edit/:id',
    name: 'roles.edit',
    component: page('roles/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // Permission
  {
    path: '/permissions',
    name: 'permissions',
    component: page('permissions/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/permissions/create',
    name: 'permissions.create',
    component: page('permissions/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/permissions/edit/:id',
    name: 'permissions.edit',
    component: page('permissions/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // Delivery
  {
    path: '/deliveries',
    name: 'deliveries',
    component: page('delivery/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/deliveries/create',
    name: 'deliveries.create',
    component: page('delivery/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/deliveries/edit/:id',
    name: 'deliveries.edit',
    component: page('delivery/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/deliveries/statuses',
    name: 'deliveries.statuses',
    component: page('delivery/statuses/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // Payments
  {
    path: '/payments',
    name: 'payments',
    component: page('payment/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/payments/create',
    name: 'payments.create',
    component: page('payment/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/payments/edit/:id',
    name: 'payments.edit',
    component: page('payment/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // Statuses
  {
    path: '/statuses',
    name: 'statuses',
    component: page('statuses/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/statuses/create',
    name: 'statuses.create',
    component: page('statuses/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/statuses/edit/:id',
    name: 'statuses.edit',
    component: page('statuses/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // relation statuses
  {
    path: '/relation-statuses',
    name: 'relationStatuses',
    component: page('relationStatuses/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // Product
  {
    path: '/products',
    name: 'products',
    component: page('product/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/products/create',
    name: 'products.create',
    component: page('product/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/products/edit/:id',
    name: 'products.edit',
    component: page('product/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // Orders
  {
    path: '/orders',
    name: 'orders',
    component: page('orders/index.vue'),
    beforeEnter: (to, from, next) => {
      if (
        getRole() === 'admin' ||
        getRole() === 'manager' ||
        getRole() === 'dropshipper'
      ) {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/orders/create',
    name: 'orders.create',
    component: page('orders/create.vue'),
    beforeEnter: (to, from, next) => {
      if (
        getRole() === 'admin' ||
        getRole() === 'manager' ||
        getRole() === 'dropshipper'
      ) {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/orders/create/preview',
    name: 'orders.create.preview',
    component: page('orders/preview.vue'),
    beforeEnter: (to, from, next) => {
      if (
        getRole() === 'admin' ||
        getRole() === 'manager' ||
        getRole() === 'dropshipper'
      ) {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/orders/edit/:id',
    name: 'orders.edit',
    component: page('orders/edit.vue'),
    beforeEnter: (to, from, next) => {
      if (
        getRole() === 'admin' ||
        getRole() === 'manager' ||
        getRole() === 'dropshipper'
      ) {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  // { path: '/order/products/edit/:id', name: 'order.products.edit', component: page('orders/editProducts.vue') },
  // customer & groups
  {
    path: '/customers',
    name: 'customers',
    component: page('customers/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'manager' || getRole() === 'dropshipper') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/customers/create',
    name: 'customers.create',
    component: page('customers/create.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'manager' || getRole() === 'dropshipper') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/customers/edit/:id',
    name: 'customers.edit',
    component: page('customers/edit.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'manager' || getRole() === 'dropshipper') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/customer-groups',
    name: 'customerGroups',
    component: page('customers/group/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'manager' || getRole() === 'dropshipper') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/customer-groups/create',
    name: 'customerGroups.create',
    component: page('customers/group/create.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'manager' || getRole() === 'dropshipper') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/customer-groups/edit/:id',
    name: 'customerGroups.edit',
    component: page('customers/group/edit.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'manager' || getRole() === 'dropshipper') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  // Work
  {
    path: '/history',
    name: 'history',
    component: page('history/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'worker') {
        // console.log(true);
        next()
      } else {
        // console.log(false);
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/production',
    name: 'production',
    component: page('production/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'worker') {
        // console.log(true);
        next()
      } else {
        // console.log(false);
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/production-process',
    name: 'production-process',
    component: page('process/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'worker') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/production-process/create',
    name: 'production-process.create',
    component: page('process/create.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'worker') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/production-process/edit/:id',
    name: 'production-process.edit',
    component: page('process/edit.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'worker') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  // Tasks
  {
    path: '/tasks',
    name: 'tasks',
    component: page('tasks/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'worker') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  // Storage
  {
    path: '/storage',
    name: 'storage',
    component: page('storage/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'manager' || getRole() === 'dropshipper') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/notification',
    name: 'notification',
    component: page('notification/index.vue')
  },
  // Worker settings
  {
    path: '/worker',
    name: 'worker.settings',
    component: page('worker/index.vue')
  },
  {
    path: '/packaging',
    name: 'packaging',
    component: page('packaging/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'worker') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  {
    path: '/parties',
    name: 'parties',
    component: page('parties/index.vue'),
    beforeEnter: (to, from, next) => {
      if (getRole() === 'worker') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },
  // Specification
  {
    path: '/specification',
    name: 'specification',
    component: page('specification/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // checkbox integration
  {
    path: '/checkbox',
    name: 'checkbox',
    component: page('checkbox/index.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/checkbox/create',
    name: 'checkbox.create',
    component: page('checkbox/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/checkbox/edit/:id',
    name: 'checkbox.edit',
    component: page('checkbox/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // Operations
  {
    path: '/operation/settings',
    name: 'operation.settings',
    component: page('operation/settings.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/operation/section/:slug',
    name: 'operation.section',
    component: page('operation/section.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  // {
  //   path: "/operation/types/additional",
  //   name: "operation.types.additional",
  //   component: page("operation/types/additional/index.vue"),
  //   beforeEnter: (to, from, next) => {
  //     openOnlyAdmin(next);
  //   }
  // },
  {
    path: '/operation/parts/:id',
    name: 'operation.parts',
    component: page('operation/section.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/operation/counterparties',
    name: 'operation.counterparties',
    component: page('operation/counterparties.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/operation/finance',
    name: 'operation.finance',
    component: page('operation/finance.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/operation/finance/create',
    name: 'operation.finance.create',
    component: page('operation/finance/create.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/operation/finance/:type/:id/:copy',
    name: 'operation.finance.edit',
    component: page('operation/finance/edit.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/operation/finance/related/:related_operation_id/:related_operation_number',
    name: 'operation.finance.related',
    component: page('operation/finance/create.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/operation/show/:id',
    name: 'operation.finance.show',
    component: page('operation/finance/show.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/operation/cash-registers',
    name: 'operation.cash.registers',
    component: page('operation/cashRegister.vue'),
    beforeEnter: (to, from, next) => {
      openOnlyAdmin(next)
    }
  },
  {
    path: '/company/:id',
    name: 'company.info',
    component: page('operation/company.vue')
  },
  // errors
  { path: '*', name: '404', component: page('errors/404.vue') }
]
