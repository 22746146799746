<template>
  <div class="default-layout">
    <navbar/>
    <div class="container-fluid mt-2">
      <child />
    </div>
  </div>
</template>

<script>
import Navbar from '~/components/Navbar'
export default {
  name: 'DefaultLayout',
  components: {
    Navbar
  }
}
</script>
