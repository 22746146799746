import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    locale: {
        locale: 'uk',
    },
    date: {
        locale: {
            uk: 'uk',
        },
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#2196f3',
                secondary: '#607d8b',
                accent: '#4caf50',
                error: '#f44336',
                warning: '#ff9800',
                info: '#00bcd4',
                success: '#8bc34a'
            },
            dark: {
                primary: '#2196f3',
                secondary: '#607d8b',
                accent: '#4caf50',
                error: '#f44336',
                warning: '#ff9800',
                info: '#00bcd4',
                success: '#8bc34a'
            }
        },
    },
});
