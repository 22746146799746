import { Base64 } from "js-base64";

class Order {
  constructor(order) {
    this.id = order.id;
    this.orderNum = order.order_num || "";
    this.delivery = order.delivery;
    this.products = Array.isArray(order.products)
      ? order.products
      : order.products?.data || [];
    this.comment = order.comment;
    this.summary = order.summary || {};
    this.deliverySummary = order.deliverySummary || {};
    this.client = order.client;
    this.created_at = order.created_at;
    this.default_payment = 0;
    this.edit = Boolean(order?.edit);
  }

  setDeliveryType(val) {
    this.delivery_type = val;
  }

  setDeliveryId(deliveries) {
    if (deliveries) {
      const delivery = deliveries.find(
        (d) => d.slug === this.delivery.delivery_method
      );

      if (delivery) {
        this.delivery.delivery_id = delivery.id;
        this.default_payment = delivery.default_payment;
      }
    }
  }

  setProductQuantity(qty, productId) {
    const product = this.products.find((p) => p.uuid === productId);
    if (product) {
      product.quantity = qty;
    }
    return this;
  }

  setClientProductPrice(price, productId, characteristicId) {
    const product = this.products.find((p) => p.uuid === productId);
    if (product) {
      const unitPrice = price / product.quantity;
      if (
        !product.is_order_edit &&
        (product.characteristics || []).length > 0
      ) {
        const characteristic = product.characteristics.find(
          (c) => c.id === characteristicId
        );
        if (characteristic) {
          characteristic.price = unitPrice;
        }
      } else {
        product.price = unitPrice;
      }

      product.total_price = price;
    }
    return this;
  }

  setPaymentMethod(methodId) {
    this.deliverySummary.paymentMethod = methodId;
  }

  getTotalPrice() {
    const dCost = parseFloat(this.summary.delivery_cost) || 0;
    const deposit = parseFloat(this.deliverySummary.deposit) || 0;

    const prices = {
      delivery_cost: dCost,
      total: 0,
      total_drop: 0,
      deposit_total: 0,
    };

    const productPrices = this.updateProductPrice();
    prices.total = productPrices.total;
    prices.total_drop = productPrices.total_drop;

    const { depositPaymentType, depositPaymentStatus } = this.deliverySummary;
    if (
      !depositPaymentType ||
      depositPaymentStatus === "paid" ||
      depositPaymentStatus === "unpaid"
    ) {
      prices.deposit_total = prices.total - deposit;
    } else {
      prices.deposit_total = prices.total;
    }

    this.summary = prices;
    return prices;
  }

  getDeliveryMethodId() {
    return this.delivery.delivery_id;
  }

  getPaymentMethodId() {
    return this.deliverySummary.paymentMethod;
  }

  getDeliveryMethod() {
    return this.delivery.delivery_method;
  }

  updateOrderStatuses(statuses) {
    if (statuses) {
      const role = localStorage.getItem("role");
      statuses.forEach((s) => {
        if (role === "manager" || role === "dropshipper") {
          s.visible = ["Новий", "Відміна", "Робити пізніше"].includes(s.name);
        } else if (role === "admin") {
          s.visible = true;
        }
      });

      const selected = statuses.find((s) => s.selected);
      if (selected && selected.id) {
        this.deliverySummary.orderStatus = selected.id;
      }

      return statuses.filter((s) => s.visible);
    }
  }

  updatePaymentStatuses(statuses) {
    if (statuses) {
      const deliveryMethodId = this.getDeliveryMethodId();
      statuses.forEach((s) => {
        s.visible = (s.show_for || []).includes(deliveryMethodId);
      });

      const selected = statuses.find((s) => s.selected);
      if (selected && selected.id) {
        this.deliverySummary.paymentStatus = selected.id;
      }

      return statuses.filter((s) => s.visible);
    }
  }

  paymentsToDelivery(payments) {
    if (payments) {
      const deliveryMethodId = this.getDeliveryMethodId();
      payments.forEach((p) => {
        p.visible = (p.show_for || []).includes(deliveryMethodId);
      });

      this.deliverySummary.paymentMethod = this.default_payment;
      return payments.filter((p) => p.visible);
    }
  }

  paymentsToPayments(statuses) {
    if (statuses) {
      const paymentMethodId = this.getPaymentMethodId();
      statuses.forEach((s) => {
        const showForPayments = s.show_for_payments || [];
        s.visible_payment = showForPayments.some((id) => id == paymentMethodId);
      });

      const selected = statuses.find((s) => s.selected);
      if (selected && selected.id && !this.deliverySummary.paymentStatus) {
        this.deliverySummary.paymentStatus = selected.id;
      }

      return statuses.filter((s) => s.visible_payment);
    }
  }

  updateProductPrice() {
    const prices = { total: 0, total_drop: 0 };

    const calculatePrice = (price, qty, priceDrop) => ({
      totalPrice: price * qty,
      totalPriceDrop: priceDrop * qty,
    });

    if (Array.isArray(this.products)) {
      this.products.forEach((item) => {
        let totalPrice = 0;
        let totalPriceDrop = 0;
        const qty = Number(item.quantity);

        const characteristics = item.characteristics || [];

        if (!item.is_order_edit && characteristics.length > 0) {
          const characteristic = characteristics.find(
            (c) => c.id === item.characteristic_id
          );
          if (characteristic) {
            const price = characteristic.price;
            const priceDrop = characteristic.price_drop || price;
            ({ totalPrice, totalPriceDrop } = calculatePrice(
              price,
              qty,
              priceDrop
            ));
          }
        } else {
          const price = item.price;
          const priceDrop = item.price_drop || price;
          ({ totalPrice, totalPriceDrop } = calculatePrice(
            price,
            qty,
            priceDrop
          ));
        }

        if ((!totalPrice || !totalPriceDrop) && this.edit && !item.new) {
          totalPrice = item.total_price;
          totalPriceDrop = item.total_price_drop;
        } else {
          item.total_price = totalPrice;
          item.total_price_drop = totalPriceDrop;
        }

        if (characteristics.length === 0) {
          item.characteristic_id = "";
        }

        prices.total += totalPrice;
        prices.total_drop += totalPriceDrop;
      });
    }
    return prices;
  }

  setDeliveryCost(data) {
    const deliveryCost = data.deliveryCost || 0;
    this.summary.delivery_cost = deliveryCost;
    this.deliverySummary.delivery_cost = deliveryCost;
    this.deliverySummary.delivery_data = data.deliveryDate || "";
  }

  saveOrder() {
    return this.orderSummary();
  }

  getOrderData() {
    return {
      id: this.id,
      orderNum: this.orderNum,
      client: this.client,
      delivery: this.delivery,
      products: this.products,
      comment: this.comment,
      deliverySummary: this.deliverySummary,
      summary: this.summary,
      created_at: this.created_at,
    };
  }

  orderSummary() {
    const products = this.products?.map((p) => {
      const characteristics = p.characteristics || [];
      const characteristic = characteristics.find(
        (c) => c.id === p.characteristic_id
      );

      const baseProduct = {
        id: p.product_id,
        total_price: p.total_price,
        total_price_drop: p.total_price_drop,
        quantity: p.quantity,
        status: p.status,
      };

      if (characteristic) {
        baseProduct.characteristicId = p.characteristic_id;
        baseProduct.characteristic = {
          id: characteristic.id,
          price: characteristic.price,
          price_drop: characteristic.price_drop,
          price_dropshipper: characteristic.price_dropshipper,
        };
      }

      if (p.stages) {
        baseProduct.stages = p.stages.map((s) => ({
          id: s.id,
          stage_id: s.stage_id,
          photo: s.photo,
          photoLayout: s.photoLayout || null,
          photo_layout: s.photo_layout || null,
          status: s.status || "Новий",
          ready: s.ready || 0,
        }));
      }

      return baseProduct;
    });

    const data = {
      id: this.id,
      client: this.client,
      delivery: this.delivery,
      products: products,
      comment: this.comment,
      deliverySummary: this.deliverySummary,
      deposit: this.deposit,
      summary: this.summary,
    };

    return { order: Base64.encode(JSON.stringify(data)), data };
  }

  updateOrder(updateData) {
    Object.entries(updateData).forEach(([key, value]) => {
      if (key in this) {
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          this[key] = { ...this[key], ...value };
        } else {
          this[key] = value;
        }
      }
    });
  }
}

export default Order;
