import Order from "~/lib/order";
import * as types from "../mutation-types";
import axios from "axios";
import Swal from "sweetalert2";

const apiUrl = process.env.VUE_APP_ENDPOINT_V1;

// state
export const state = {
  deliveries: null,
  delivery: {
    delivery_method: "",
    delivery_id: "",
    delivery_type: "",
    city: "",
    warehouse: "",
    warehouse_ref: "",
    entity: "individual",
    zip_code: "",
    apartment_number: "",
    house_number: "",
    delivery: "",
    delivery_date: "",
    delivery_status: "",
    city_name: "",
    street: "",
    delivery_address: "",
    shipment_type: "EXPRESS",
    check_on_delivery_allowed: true,
  },
  payments: [],
  statuses: [],
  deliveryTypes: {
    "nova-posta": [
      { id: "WarehouseWarehouse", name: "Склад-Склад" },
      { id: "WarehouseDoors", name: "Склад-Двері" },
    ],
    "ukr-posta": {
      types: [
        { id: "EXPRESS", name: "Express" },
        { id: "STANDARD", name: "Standard" },
      ],
      methods: [
        { id: "W2D", name: "Склад-Двері" },
        { id: "W2W", name: "Склад-Склад" },
      ],
    },
  },
  order: null,
  totalPrice: { total: 0, total_drop: 0 },
  deliveryData: {},
  loading: false,
  orderNum: false,
  orderStatuses: null,
  paymentStatuses: null,
  paymentsToDelivery: null,
  client: {
    id: null,
    first_name: null,
    last_name: null,
    surname: null,
    phone: "",
    email: null,
    group_id: null,
    company: null,
    description: null,
    addresses: null,
  },
  orderId: null,
  msg: "",
  comment: "",
  statusEdit: false,
  typeOfAlert: "danger",
  editOn: false,
  editRecipientData: false,
  editDeliveryData: false,
  editDeliveryStatusData: false,
  editProductsData: false,
  btnAddProductStatus: false,
  setDisabledOrderSelect: false,
};

// getters
export const getters = {
  deliveries: (state) => state.deliveries,
  delivery: (state) => state.delivery,
  payments: (state) => state.payments,
  statuses: (state) => state.statuses,
  client: (state) => state.client,
  deliveryTypes: (state) => state.deliveryTypes,
  order: (state) => state.order?.getOrderData(),
  totalPrice: (state) => state.totalPrice,
  deliveryData: (state) => state.deliveryData,
  loading: (state) => state.loading,
  orderNum: (state) => state.orderNum,
  orderStatuses: (state) => state.orderStatuses,
  paymentStatuses: (state) => state.paymentStatuses,
  paymentsToDelivery: (state) => state.paymentsToDelivery,
  orderSummary: (state) => state.order?.orderSummary(),
  orderId: (state) => state.orderId,
  msg: (state) => state.msg,
  comment: (state) => state.comment,
  statusEdit: (state) => state.statusEdit,
  typeOfAlert: (state) => state.typeOfAlert,
  editOn: (state) => state.editOn,
  printed: (state) => state.order?.delivery.printed,
  editRecipientData: (state) => state.editRecipientData,
  editDeliveryData: (state) => state.editDeliveryData,
  editDeliveryStatusData: (state) => state.editDeliveryStatusData,
  editProductsData: (state) => state.editProductsData,
  btnAddProductStatus: (state) => state.btnAddProductStatus,
  setDisabledOrderSelect: (state) => state.setDisabledOrderSelect,
};

// mutations
export const mutations = {
  [types.SET_DELIVERIES](state, deliveries) {
    state.deliveries = deliveries;
  },
  [types.SET_PAYMENTS](state, payments) {
    state.payments = payments;
  },
  [types.SET_CLIENT](state, client) {
    state.client = client;
  },
  [types.SET_DELIVERY](state, delivery) {
    state.delivery = delivery;
  },
  UPDATE_DELIVERY(state, delivery) {
    state.delivery = { ...state.delivery, ...delivery };
  },
  [types.UPDATE_ORDER](state, order) {
    state.order.updateOrder(order);
  },
  [types.SET_STATUSES](state, statuses) {
    state.statuses = statuses;
  },
  [types.SET_ORDER_STATUSES](state, statuses) {
    state.orderStatuses = statuses;
  },
  [types.SET_PAYMENT_STATUSES](state, statuses) {
    state.paymentStatuses = statuses;
  },
  [types.SET_PAYMENTS_TO_DELIVERY](state, data) {
    state.paymentsToDelivery = data;
  },
  [types.SET_ORDER](state, order) {
    state.order = order;
  },
  [types.SET_TOTAL_PRICE](state, total) {
    state.totalPrice = total;
  },
  [types.SET_LOADING](state, status) {
    state.loading = status;
  },
  [types.SET_DELIVERY_DATA](state, value) {
    state.deliveryData = value;
  },
  [types.SET_ORDER_NUM](state, value) {
    state.orderNum = value;
  },
  [types.SET_ORDER_ID](state, id) {
    state.orderId = id;
  },
  [types.SET_MSG](state, msg) {
    state.msg = msg;
  },
  [types.SET_COMMENT](state, comment) {
    state.comment = comment;
  },
  [types.SET_STATUS_EDIT](state, status) {
    state.statusEdit = status;
  },
  [types.SET_EDITABLE](state, status) {
    state.editOn = status;
  },
  [types.SET_TYPE_OF_ALERT](state, value) {
    state.typeOfAlert = value;
  },
  [types.SET_EDITABLE_RECIPIENT](state, status) {
    state.editRecipientData = status;
  },
  [types.SET_EDITABLE_DELIVERY](state, status) {
    state.editDeliveryData = status;
  },
  [types.SET_EDITABLE_DELIVERY_STATUS](state, status) {
    state.editDeliveryStatusData = status;
  },
  [types.SET_EDITABLE_PRODUCTS](state, status) {
    state.editProductsData = status;
  },
  [types.SET_BTN_ADD_PRODUCT](state, status) {
    state.btnAddProductStatus = status;
  },
  [types.SET_DISABLED_ORDER_STATUS](state, status) {
    state.setDisabledOrderSelect = status;
  },
};

// actions
export const actions = {
  async setDeliveries({ commit, state, getters }) {
    try {
      const { data } = await axios.get(`${apiUrl}deliveries`);
      if (data.data.length > 0) {
        commit(types.SET_DELIVERIES, data.data);
        if (state.order) {
          state.order.setDeliveryId(getters.deliveries);
        }
      }
    } catch (error) {
      console.error("Error fetching deliveries:", error);
    }
  },
  async setPayments({ commit }) {
    try {
      const { data } = await axios.get(`${apiUrl}payments`);
      const payments = data.data.filter((d) => d.status === 1);
      commit(types.SET_PAYMENTS, payments);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  },
  setClient({ commit }, payload) {
    commit(types.SET_CLIENT, payload);
  },
  setDelivery({ commit }, payload) {
    commit(types.SET_DELIVERY, payload);
  },
  async setStatuses({ commit }) {
    try {
      const { data } = await axios.get(`${apiUrl}statuses`);
      commit(types.SET_STATUSES, data.data);
      commit(
        types.SET_ORDER_STATUSES,
        data.data.filter((s) => s.type === "order")
      );
      commit(
        types.SET_PAYMENT_STATUSES,
        data.data.filter((s) => s.type === "payment")
      );
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
  },
  setOrder({ commit, state }, order) {
    commit(types.SET_STATUS_EDIT, false);
    commit(types.SET_MSG, null);
    commit(types.SET_TYPE_OF_ALERT, "danger");

    const orderData = new Order(order);
    commit(types.SET_ORDER, orderData);

    commit(types.SET_LOADING, false);
    commit(types.SET_DELIVERY_DATA, {});
    commit(types.SET_EDITABLE_RECIPIENT, false);
    commit(types.SET_EDITABLE_DELIVERY, false);
    commit(types.SET_EDITABLE_DELIVERY_STATUS, false);
    commit(types.SET_EDITABLE_PRODUCTS, false);

    state.order.getTotalPrice();
  },
  updateOrderData({ commit }, order) {
    commit(types.UPDATE_ORDER, order);
  },
  changeDeliveryType({ state }, val) {
    state.order.setDeliveryType(val.target.value);
  },
  updateTotalPrice({ state, commit }) {
    commit(types.SET_TOTAL_PRICE, state.order.getTotalPrice());
  },
  changeProductQty({ state, commit }, { qty, productId }) {
    state.order.setProductQuantity(qty, productId);
    commit(types.SET_TOTAL_PRICE, state.order.getTotalPrice());
  },
  changeTotalClientPrice({ state, commit }, { price, productId, characteristicId }) {
    state.order.setClientProductPrice(price, productId, characteristicId);
    commit(types.SET_TOTAL_PRICE, state.order.getTotalPrice());
  },
  setDeliveryData({ commit, state }, data) {
    state.order.setDeliveryCost(data);
    commit(types.SET_DELIVERY_DATA, data);
  },
  updateStatuses({ commit, state, getters, dispatch }) {
    state.order.setDeliveryId(getters.deliveries);
    if (getters.statuses) {
      const orderStatuses = getters.statuses.filter((s) => s.type === "order");
      const order = state.order.updateOrderStatuses(orderStatuses);
      commit(types.SET_ORDER_STATUSES, order);
    }

    if (getters.paymentStatuses) {
      const paymentsTo = state.order.paymentsToDelivery(getters.payments);
      commit(types.SET_PAYMENTS_TO_DELIVERY, paymentsTo);
      const payments = state.order.paymentsToPayments(getters.paymentStatuses);
      // commit(types.SET_PAYMENT_STATUSES, payments);
    }
    dispatch("setOrderStatusByRelation");
  },
  setPaymentStatuses({ commit, state, getters, dispatch }, paymentMethodId) {
    if (getters.payments) {
      state.order.setPaymentMethod(paymentMethodId);
      const paymentStatuses = getters.statuses.filter((s) => s.type === "payment");
      const payments = state.order.paymentsToPayments(paymentStatuses);
      commit(types.SET_PAYMENT_STATUSES, payments);
      dispatch("setOrderStatusByRelation");
    }
  },
  setOrderStatusByRelation({ commit, state, getters }) {
    const { payment, paymentStatus } = state.order.deliverySummary;
    const paymentMethod = payment || state.order.deliverySummary.paymentMethod;

    if (getters.payments) {
      const relation = getters.payments.find((s) => s.id === paymentMethod);
      commit(types.SET_DISABLED_ORDER_STATUS, false);
      if (relation) {
        relation.relation_statuses.forEach((r) => {
          if (r.p === paymentMethod && paymentStatus === r.ps) {
            commit(types.SET_DISABLED_ORDER_STATUS, true);
            state.order.deliverySummary.orderStatus = r.os;
          }
        });
      }
    }

    if (!state.setDisabledOrderSelect && getters.statuses) {
      const newStatus = getters.statuses.find(
        (s) => s.type === "order" && s.name === "Новий"
      );
      if (newStatus) {
        state.order.deliverySummary.orderStatus = newStatus.id;
      }
    }
  },
  editCheck({ commit, state }, mutationName) {
    const printed = state.order.delivery.printed;
    const orderStatusName = state.order.deliverySummary.orderStatusName;
    const products = state.order.products || [];
    const confirmStatuses = ["Новий", "Створено"];

    if (printed) {
      if (mutationName === "SET_EDITABLE_DELIVERY") {
        commit(types[mutationName], true);
      } else {
        commit(types.SET_STATUS_EDIT, true);
        commit(
          types.SET_MSG,
          mutationName === "SET_EDITABLE_PRODUCTS"
            ? "Товар уже редагувати не можна, ТТН роздруковано"
            : "На даному етапі редагувати дані отримувача неможливо. ТТН була роздрукована. Дочекайтеся відправки і спробуйте ще раз."
        );
        commit(types[mutationName], false);
      }
    } else {
      if (mutationName === "SET_EDITABLE_PRODUCTS") {
        const hasNewProducts = products.some((p) => p.status === "Новий");
        const canAddProduct =
          hasNewProducts ||
          confirmStatuses.includes(orderStatusName);

        commit(types.SET_BTN_ADD_PRODUCT, canAddProduct);
      } else {
        commit(types[mutationName], true);
      }
    }
  },
  async checkReneval({ state }) {
    try {
      const { data } = await axios.post(`${apiUrl}orders/checkReneval`, {
        ttn: state.order.delivery.internet_document,
        delivery_method: state.order.delivery.delivery_method,
      });
      return data;
    } catch (error) {
      console.error("Error checking renewal:", error);
    }
  },
  async updateOrder({ commit, getters }, { id, type }) {
    const orderData = getters.order;

    const filteredProducts = orderData.products.filter(
      (product) => product.product_id && product.price && product.total_price
    );

    const order = {
      orderEditType: type,
      ...orderData,
      products: filteredProducts,
    };

    try {
      const { data } = await axios.post(`${apiUrl}orders/updateOrder/${id}`, order);
      const alertConfig = {
        icon: data.status ? "success" : "error",
        title: data.status ? "Дані оновлено" : "Помилка оновлення даних",
        text: data.msg,
        confirmButtonText: "Ok",
      };

      Swal.fire(alertConfig);

      if (data.status) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating order:", error);
    }
  },
  async checkDeliveryRedirecting({ commit }, id) {
    try {
      const { data } = await axios.post(`${apiUrl}orders/checkDeliveryRedirecting/${id}`);
      if (data.status) {
        commit(types.SET_EDITABLE_DELIVERY, true);
      } else {
        commit(types.SET_STATUS_EDIT, true);
        commit(types.SET_MSG, data.msg);
      }
    } catch (error) {
      console.error("Error checking delivery redirecting:", error);
    }
  },
  async deliveryRedirecting({ commit, getters }, id) {
    const orderData = getters.order;
    try {
      const { data } = await axios.post(`${apiUrl}orders/deliveryRedirecting/${id}`, orderData);
      commit(types.SET_STATUS_EDIT, true);
      commit(types.SET_MSG, data.msg);
      commit(types.SET_TYPE_OF_ALERT, data.status ? "success" : "danger");
    } catch (error) {
      console.error("Error in delivery redirecting:", error);
    }
  },
  async clientEditCheck({ commit }, id) {
    try {
      const { data } = await axios.post(`${apiUrl}orders/checkChangeEWRecipient/${id}`);
      if (data.status) {
        commit(types.SET_EDITABLE_RECIPIENT, true);
      } else {
        commit(types.SET_STATUS_EDIT, true);
        commit(types.SET_MSG, data.msg);
      }
    } catch (error) {
      console.error("Error checking client edit:", error);
    }
  },
  async saveEWRecipient({ commit, getters }, id) {
    const orderData = getters.order;
    try {
      const { data } = await axios.post(`${apiUrl}orders/saveEWRecipient/${id}`, orderData);
      commit(types.SET_STATUS_EDIT, true);
      commit(types.SET_MSG, data.msg);
      commit(types.SET_TYPE_OF_ALERT, data.status ? "success" : "danger");
    } catch (error) {
      console.error("Error saving EW recipient:", error);
    }
  },
  closeEditAlert({ commit }) {
    commit(types.SET_STATUS_EDIT, false);
    commit(types.SET_MSG, "");
  },
};
