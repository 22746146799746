import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import { } from '@fortawesome/free-regular-svg-icons'

import {
  faCaretRight,
  faCaretDown,
  faChevronCircleRight,
  faChevronCircleLeft,
  faShoppingCart,
  faArrowCircleRight,
  faArrowCircleLeft,
  faTrash,
  faUser,
  faTruck,
  faComment,
  faEuroSign,
  faInfoCircle,
  faPencilAlt,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faPlus,
  faFileExport,
  faFunnelDollar,
  faMinus,
  faEnvelope,
  faSync,
  faFilter,
  faCheck,
  faBan,
  faPrint,
  faBoxOpen,
  faRegistered,
  faTimes,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faCopy,
  faCaretUp,
  faMoneyCheck, faCogs, faCog, faSave
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faCaretRight,
  faCaretDown,
  faChevronCircleRight,
  faChevronCircleLeft,
  faShoppingCart,
  faArrowCircleRight,
  faArrowCircleLeft,
  faTrash,
  faUser,
  faTruck,
  faComment,
  faEuroSign,
  faInfoCircle,
  faPencilAlt,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faPlus,
  faMinus,
  faFileExport,
  faFunnelDollar,
  faEnvelope,
  faSync,
  faFilter,
  faCheck,
  faBan,
  faPrint,
  faBoxOpen,
  faRegistered,
  faTimes,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faCopy,
  faCaretUp,
  faMoneyCheck,
  faCogs,
  faCog,
  faSave
)

Vue.component('fa', FontAwesomeIcon)
