import store from '~/store'

export default (to, from, next, roles) => {
    if (roles && Array.isArray(roles)) {
        const role = roles.find(r => r === store.getters['auth/hasRole'])
        if (typeof role === 'undefined') {
            return next({ name: 'dashboard' })
        }
    }
    return next()
}
