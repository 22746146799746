import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'
import Swal from 'sweetalert2'
// state
export const state = {
  shift: Cookies.get('shift'),
  productions: null,
  partiesValue: 0,
  processId: Cookies.get('processId') ? Cookies.get('processId') : null
}

// getters
export const getters = {
  shift: state => {
    if (state.shift) {
      return state.shift
    }
    return null
  },
  productions: state => state.productions,
  partiesValue: state => state.partiesValue,
  processId: state => state.processId
}

// mutations
export const mutations = {
  [types.SAVE_SHIFT] (state, { shift }) {
    const date = new Date()
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000)
    state.shift = shift
    Cookies.set('shift', shift, { expires: date })
  },
  [types.SET_PRODUCTIONS] (state, { productions }) {
    state.productions = productions
  },
  [types.REMOVE_SHIFT] (state) {
    state.shift = null
    Cookies.remove('shift')
  },
  [types.SET_PARTIES_VALUE] (state, { partiesValue }) {
    state.partiesValue = partiesValue
  },
  [types.SET_PROCESS] (state, process) {
    state.processId = process
  }
}

// actions
export const actions = {
  async fetchWorkerShift ({ commit }) {
    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
    const { data } = await axios.post(apiUrl + 'shift')
    if (typeof data.data !== 'undefined') {
      commit(types.SAVE_SHIFT, { shift: data.data })
    } else {
      commit(types.SAVE_SHIFT, { shift: data.status })
    }
  },
  async openShift ({ commit }) {
    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
    await axios.post(apiUrl + 'shift/open').then(resp => {})
  },
  async closeShift ({ commit, state }) {
    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
    await axios.post(apiUrl + 'shift/close', { shift: state.shift.id }).then(resp => {
      if (resp.data.status) {
        Swal.fire({
          icon: 'success',
          title: 'Інфо',
          html: resp.data.msg,
          reverseButtons: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'Cancel'
        })
        commit(types.REMOVE_SHIFT)
        window.location.reload()
      } else {
        let html = ''
        
        if (resp.data.result) {
          const result = Object.entries(resp.data.result)
          result.forEach(el => {
            html += '<h5 class="text-left">' + el[0] + '</h5>'
            if (el[1]) {
              html += '<ol class="text-left">'
              el[1].forEach(s => {
                html += '<li>'
                html += '<strong>Замовлення:</strong> ' + s.order_num + '<br/>Товар: ' + s.product + '<br/>'
                html += '<strong>Етап:</strong> <small class="text-danger">' + s.stage + '</small><br/>'
                html += '<strong>Потрібно виг./готово:</strong> <span class="text-warning">' + s.quantity + '</span>/' + '<span class="text-danger">' + s.ready + '</span>'
                html += '</li>'
              })
              html += '</ol>'
            }
          })
          Swal.fire({
            icon: 'warning',
            title: 'Інфо',
            html: html,
            reverseButtons: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
          })
        }
      }
    })
  },
  async remove ({ commit }) {
    commit(types.REMOVE_SHIFT)
  },
  async fetchProductions ({ commit }) {
    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
    const { data } = await axios.post(apiUrl + 'working/process')
    commit(types.SET_PRODUCTIONS, { productions: data })
  },
  async fetchPartiesValue ({ commit }) {
    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
    const { data } = await axios.get(apiUrl + 'worker/config')
    commit(types.SET_PARTIES_VALUE, { partiesValue: data })
  },
  setProcessId ({ commit }, processId) {
    commit(types.SET_PROCESS, processId)
    Cookies.set('processId', processId)
  }
}
